<template>
    <div class="mx-auto home">
        <small-card 
            :title="title"
            :descr="content"
        />
    </div>
</template>

<script>
import SmallCard from '@/components/cards/small'

export default {
    components: {
        SmallCard
    },
    
    computed: {
        title() {
            return `<h3 style="text-align: center;"><strong>Disclaimer</strong></h3>`
        },

        content() {
            return `
                <p style="text-align: justify;"><br /><strong>Notice l&eacute;gale</strong><br />En acc&eacute;dant et en parcourant le site atsserrurier.be, le visiteur accepte, sans r&eacute;serve, la pr&eacute;sente notice l&eacute;gale. Cette notice peut &ecirc;tre modifi&eacute;e, &agrave; tout moment, par simple publication sur le Site pr&eacute;cit&eacute; d&rsquo;une nouvelle notice, et ce, sans n&eacute;cessit&eacute; de faire parvenir une notification au visiteur du Site.<br />La modification entre en vigueur &agrave; la date de sa mise &agrave; disposition sur le Site.<br />En cas de litige, seul le droit belge sera applicable et seuls les tribunaux de l&rsquo;arrondissement judiciaire du si&egrave;ge de l&rsquo;entreprise seront comp&eacute;tents.</p>
                <p style="text-align: justify;"><br /><strong><span style="font-size: 11pt;">Identification du propri&eacute;taire du Site</span></strong><br />Le Site appartient &agrave; Arnaud Toussaint, enregistr&eacute; aupr&egrave;s de la Banque-Carrefour des Entreprises sous le num&eacute;ro 0699.607.847, dont le si&egrave;ge est situ&eacute; Rue du Roi Pahaut, 25 &agrave; 4140 Sprimont, ci-apr&egrave;s &laquo;&nbsp;A.T.S. SERRURIER &raquo; ou &laquo;&nbsp;l&rsquo;entreprise&nbsp;&raquo;. <br />Le Site est g&eacute;r&eacute; par Arnaud Toussaint, propri&eacute;taire du Site et par Valentin Gr&eacute;goire, webmaster du Site. <br />Le Site&nbsp;est un site internet vitrine visant &agrave; promouvoir les services d&rsquo;A.T.S. SERRURIER, &agrave; savoir des services de serrurerie sur rendez-vous ou en cas d&rsquo;urgence. <br />A.T.S. SERRURIER est joignable par e-mail &agrave; l&rsquo;adresse suivante&nbsp;: ats.serrurier@gmail.com, ou via le formulaire de contact repris sur le Site. <br />Le Site est h&eacute;berg&eacute; par lws.fr.</p>
                <p style="text-align: justify;"><br /><strong>Objet du Site</strong><br />Le Site a pour objet la promotion des services d&rsquo;A.T.S. SERRURIER et l&rsquo;information au public quant aux coordonn&eacute;es de contact de l&rsquo;entreprise.</p>
                <p style="text-align: justify;"><strong>Contact</strong><br />Les coordonn&eacute;es de contact sont les suivantes&nbsp;: ats.serrurier@gmail.com.<br />Le visiteur est invit&eacute; &agrave; faire usage de ces coordonn&eacute;es pour toute information au sujet du contenu du Site. Le visiteur peut &eacute;galement prendre contact avec A.T.S. SERRURIER via le formulaire de contact repris sur le Site.</p>
                <p style="text-align: justify;"><br /><strong>Donn&eacute;es &agrave; caract&egrave;re personnel</strong><br />Les donn&eacute;es &agrave; caract&egrave;re personnel suivantes du visiteur sont, le cas &eacute;ch&eacute;ant, conserv&eacute;es par A.T.S. SERRURIER:</p>
                <ul>
                <li style="text-align: justify;">son domaine, y compris son adresse IP (d&eacute;tect&eacute; automatiquement par le serveur du responsable du traitement)&nbsp;;</li>
                <li style="text-align: justify;">son nom et pr&eacute;nom&nbsp;;</li>
                <li style="text-align: justify;">son adresse postale&nbsp;(si communiqu&eacute;e) ;</li>
                <li style="text-align: justify;">son num&eacute;ro de t&eacute;l&eacute;phone (si communiqu&eacute;)&nbsp;;</li>
                <li style="text-align: justify;">son adresse e-mail&nbsp;;</li>
                </ul>
                <p style="text-align: justify;"><br />Ces donn&eacute;es sont conserv&eacute;es par A.T.S. SERRURIER, dans le respect de la loi du 8 d&eacute;cembre 1992 relative &agrave; la protection des donn&eacute;es personnelles ainsi que par le R&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et du Conseil du 27 avril 2016 relatif &agrave; la protection des personnes physiques &agrave; l'&eacute;gard du traitement des donn&eacute;es &agrave; caract&egrave;re personnel et &agrave; la libre circulation de ces donn&eacute;es (&laquo;&nbsp;RGPD &raquo;). A.T.S. SERRURIER traite les donn&eacute;es r&eacute;colt&eacute;es par le formulaire de contact conform&eacute;ment aux l&eacute;gislations pr&eacute;cit&eacute;es. Le responsable du traitement est Arnaud Toussaint.</p>
                <p style="text-align: justify;">Le visiteur b&eacute;n&eacute;ficie du droit de demander l&rsquo;acc&egrave;s &agrave; ses donn&eacute;es personnelles, ainsi que de faire rectifier des donn&eacute;es personnelles inexactes, de s&rsquo;opposer le cas &eacute;ch&eacute;ant et pour des motifs l&eacute;gitimes au traitement de ses donn&eacute;es personnelles, de demander l&rsquo;effacement, une limitation du traitement ou la portabilit&eacute; de ses donn&eacute;es personnelles, en envoyant un courrier &eacute;lectronique &agrave; l&rsquo;adresse suivante : ats.serrurier@gmail.com ou par courrier postal adress&eacute; au si&egrave;ge de l&rsquo;entreprise &agrave; l&rsquo;attention d&rsquo;Arnaud Toussaint, responsable du traitement.</p>
                <p style="text-align: justify;">Par la visite du Site et/ou par l&rsquo;utilisation du formulaire de contact, le visiteur accepte et comprend qu&rsquo;A.T.S. SERRURIER est en droit de stocker et traiter ses donn&eacute;es personnelles pour les finalit&eacute;s suivantes&nbsp;: </p>
                <ul>
                <li style="text-align: justify;">informations ponctuelles quant aux produits commercialis&eacute;s par A.T.S. SERRURIER ;</li>
                <li style="text-align: justify;">r&eacute;ponses aux questions adress&eacute;es par le visiteur &agrave; A.T.S. SERRURIER par rapport aux services propos&eacute;s&nbsp;; </li>
                <li style="text-align: justify;">ex&eacute;cution des diverses obligations contractuelles et l&eacute;gales liant le visiteur &agrave; l&rsquo;entreprise&nbsp;;</li>
                </ul>
                <p id="cookie" style="text-align: justify;">Les destinataires des donn&eacute;es r&eacute;colt&eacute;es et trait&eacute;es sont, outre le responsable du traitement lui-m&ecirc;me, ses pr&eacute;pos&eacute;s ou autres sous-traitants, s&eacute;lectionn&eacute;s avec soin, situ&eacute;s en Belgique ou dans l&rsquo;Union europ&eacute;enne, et qui collaborent avec le responsable du traitement dans le cadre de la vente de ses produits et de la fourniture de ses services. Les donn&eacute;es collect&eacute;es ne seront en aucun cas transmises &agrave; des tiers &agrave; des fins commerciales ou de marketing direct.</p>
                <p style="text-align: justify;">Afin d&rsquo;&eacute;viter tout acc&egrave;s non autoris&eacute; aux donn&eacute;es &agrave; caract&egrave;re personnel transmises par le visiteur, A.T.S. SERRURIER respecte les principes g&eacute;n&eacute;raux de s&eacute;curit&eacute; applicables aux sites internet du m&ecirc;me type que le Site.</p>
                <p style="text-align: justify;">A.T.S. SERRURIER peut divulguer &agrave; des tiers des informations personnelles sur requ&ecirc;te de toute autorit&eacute; l&eacute;galement autoris&eacute;e &agrave; en faire la demande. Le responsable du traitement peut &eacute;galement les divulguer si cette transmission est requise, en toute bonne foi, pour se conformer aux lois et r&egrave;glements, pour prot&eacute;ger ou d&eacute;fendre ses droits ou ses biens, ceux du Site ou ses utilisateurs ainsi que, dans des cas extr&ecirc;mes, la s&eacute;curit&eacute; d&rsquo;A.T.S. SERRURIER ou d&rsquo;Arnaud Toussaint, propri&eacute;taire du Site et/ou de ses clients ou du Site lui-m&ecirc;me.</p>
                <p style="text-align: justify;"><strong>Cookies</strong></p>
                <p style="text-align: justify;">A.T.S. SERRURIER utilise des cookies afin de rendre la navigation plus utile et fiable. Certains de ces cookies sont indispensables au fonctionnement du Site, d&rsquo;autres permettent d&rsquo;am&eacute;liorer l&rsquo;exp&eacute;rience du visiteur.</p>
                <p style="text-align: justify;">A.T.S. SERRURIER utilise des cookies de tra&ccedil;age via Google Analytics dans le but d&rsquo;aider le responsable du traitement &agrave; mesurer les mani&egrave;res d&rsquo;interagir des visiteurs avec le contenu du Site, et qui g&eacute;n&egrave;rent les statistiques de visite de mani&egrave;re strictement anonyme.</p>
                <p style="text-align: justify;">Si le visiteur souhaite personnaliser la gestion des cookies, il doit modifier les param&egrave;tres de son navigateur. En navigant sur le Site, le visiteur marque express&eacute;ment son accord avec la gestion des cookies telle que d&eacute;crite ci-avant.</p>
                <p style="text-align: justify;"><br /><strong>Droits intellectuels</strong><br />Arnaud Toussaint est propri&eacute;taire de tous les &eacute;l&eacute;ments constituant le Site, &agrave; savoir notamment le nom de domaine, le Site en lui-m&ecirc;me et son contenu (tels que notamment les articles, textes, images graphiques, fichiers sonores, d&rsquo;animation, ou tout autre type de fichiers contenu sur le Site).<br />Toute utilisation ou reproduction, tant temporaire que permanente, est interdite sauf si le visiteur peut d&eacute;montrer qu&rsquo;il a obtenu l&rsquo;autorisation pr&eacute;alable, expresse et &eacute;crite d&rsquo;A.T.S. SERRURIER.<br />Le cas &eacute;ch&eacute;ant, l&rsquo;information et/ou la demande d&rsquo;utilisation ou de reproduction doit &ecirc;tre effectu&eacute;e par e-mail &agrave; l&rsquo;adresse suivante&nbsp;: ats.serrurier@gmail.com.</p>
                <p style="text-align: justify;">Si le visiteur estime qu&rsquo;un ou plusieurs &eacute;l&eacute;ment(s) repris sur le Site est (sont) en contradiction avec ses propres droits intellectuels, il est invit&eacute; &agrave; prendre contact avec Arnaud Toussaint, propri&eacute;taire du Site, par e-mail &agrave; ats.serrurier@gmail.com en expliquant sa demande de mani&egrave;re pr&eacute;cise. Un contact sera pris avec le visiteur sans d&eacute;lai pour r&eacute;soudre le litige.</p>
                <p style="text-align: justify;"><br /><strong>Responsabilit&eacute;</strong><br />Le Site a &eacute;t&eacute; r&eacute;alis&eacute; de la mani&egrave;re la plus consciencieuse possible. Le Site est destin&eacute; &agrave; pr&eacute;senter diff&eacute;rents produits et services d&rsquo;A.T.S. SERRURIER, &agrave; en donner une visibilit&eacute; et &agrave; permettre au visiteur de contacter A.T.S. SERRURIER s&rsquo;il le souhaite. Les caract&eacute;ristiques des produits et services pr&eacute;sent&eacute;s sur le Site sont susceptibles d&rsquo;&eacute;voluer. En aucun cas, A.T.S. SERRURIER n&rsquo;est li&eacute; contractuellement par une information mise &agrave; disposition sur le Site.</p>
                <p style="text-align: justify;">Arnaud Toussaint ne pourra, en aucun cas, &ecirc;tre tenu responsable des dommages tant directs qu&rsquo;indirects, de quelque nature que ce soit qui pourraient &ecirc;tre caus&eacute;s par la consultation ou l&rsquo;utilisation des informations reprises sur le Site.</p>
                <p style="text-align: justify;">En outre, Arnaud Toussaint ne peut &ecirc;tre tenu responsable des lenteurs &eacute;ventuelles ou interruptions survenant au cours de l&rsquo;utilisation du Site.</p>
                <p style="text-align: justify;"><br /><strong>S&eacute;curit&eacute; du site</strong><br />A.T.S. SERRURIER met tout en &oelig;uvre pour garantir la s&eacute;curit&eacute; du Site (notamment contre les virus ou autres d&eacute;lits informatiques). Des mesures r&eacute;guli&egrave;res sont prises en vue d&rsquo;y satisfaire. Le cas &eacute;ch&eacute;ant, en cas de probl&egrave;me de s&eacute;curit&eacute; li&eacute; &agrave; l&rsquo;utilisation du Site, le visiteur est invit&eacute; &agrave; informer A.T.S. SERRURIER du probl&egrave;me. A.T.S. SERRURIER mettra tout en &oelig;uvre pour r&eacute;soudre le probl&egrave;me pos&eacute; imm&eacute;diatement ou &agrave; d&eacute;faut d&rsquo;&ecirc;tre imm&eacute;diatement possible, dans un d&eacute;lai raisonnable.</p>
                <p style="text-align: justify;"><br /><strong>Disposition finale</strong><br />La nullit&eacute; d&rsquo;un article n&rsquo;entra&icirc;ne pas la nullit&eacute; totale du pr&eacute;sent disclaimer. La nullit&eacute; d&rsquo;une clause entra&icirc;nera le remplacement de cette derni&egrave;re par une clause valable allant dans le m&ecirc;me sens. Le cas &eacute;ch&eacute;ant, A.T.S. SERRURIER proc&eacute;dera au remplacement de la clause en ins&eacute;rant une clause produisant le m&ecirc;me effet.</p>
            `
        }
    }
}
</script>
